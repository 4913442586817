export interface ArticleModelDictionary {
  [key: string]: string | ArticleModelDictionary
}

export const articleModelDictionary: ArticleModelDictionary = {
  steps: 'Шаги',
  ingredients: 'Ингредиенты',
  active: 'Статус',
  section_id: 'Рубрика',
  active_from: 'Дата и время публикации',
  republish_from: 'Дата и время повторной публикации',
  name: 'Заголовок',
  description: 'Лид',
  date: 'Дата и время публикации',
  before_steps: 'Текст перед шагами',
  after_steps: 'Текст после шагов',
  preparation_time: 'Время приготовления',
  cooking_time: 'Время готовки',
  num_servings: 'Порции',
  main_ingredient_id: 'Основной продукт',
  dishes: 'Категория блюда',
  cuisine: 'Кухня',
  code: 'Url',
  preview_text: 'Лид',
  detail_text: 'Полный текст',
  meta_title: ' Meta заголовок',
  meta_keywords: 'Meta ключевые слова',
  meta_description: 'Meta описание',
  og_title: 'Заголовок для соц.сетей',
  og_description: 'Описание для соц.сетей',
  author_id: 'Автор',
  type: 'Тип',
  is_promo: 'Пометить как «PROMO»',
  status_id: 'Статус',
  announce_type: 'Тип анонса',
  adult: 'Пометка 18+',
  show_on_main: 'Показывать на главной',
  short_url: 'Короткая ссылка',
  status_image: 'Фото готово',
  status_text: 'Текст готов',
  status_ready: 'Статья готова',
  pinAtMain: 'Закрепить на главной',
  pinAtSection: 'Закрепить в разделе',
  external_link: 'Внешняя ссылка',
  notShowMainPhoto: 'Не показывать заходное фото',
  pushArticle: 'Запланировать пуш:уведомление',
  rss_not_export: 'Не экспортировать в RSS',
  turn_off_ad: 'Отключить всю рекламу',
  use_verstka: 'Использовать редактор Verstka.io',
  is_seo: 'SEO статья',
  republished: 'Републикованная статья',
  disable_infinite_scroll: 'Отключить инфинити скролл',
  read_page_filter: 'Отобразить в рубрике Read',
  native_advertising: 'Нативная реклама',
  show_in_scroll: 'Подмешивать в инфинити скролл (только для verstka.io)',
  is_negative_words: 'В статье нет негативных слов',
  responsible_users: 'Ответственные пользователи',
  preview_image: 'Анонс новости',
  detail_image: 'Картинка в статье',
  square_image: 'Квадратное изображение',
  social_image: 'Картинка в соц.сетях',
  section_image: ' Изображение на главной и рубрике',
  tags: 'Теги',
  authors: 'Авторы',
  blockArticles: 'Дополнительные показы в скролле',
  squareImage: 'Квадратная картинка',
  socialImage: 'Картинка в соцсетях',
  detailImage: 'Заходное фото',
  sectionImage: 'Изображение на главной в рубрике',
  previewImage3: 'Вертикальное изображение',
  previewImage: 'Анонс новости',
  square_image_id: 'ID для квадратной картинке',
  social_image_id: 'ID для картинки в соцсетях',
  detail_image_id: 'ID для заходное фото',
  section_image_id: 'ID для изображения на главной в рубрике',
  in_yandex_news: 'Настройки для Дзен→ Отправить статью в Дзен Новости',
  fixed_text: 'Закрепленная статья → Текст для закрепа на главной в 1-м экране',
  fixed_position: 'Закрепить в рубрике на главной',
  issue_id: 'Статья из журнала → Статья опубликована в журнале',
  licensed_images: 'На изображения в статье есть права',
  c_use_verstka: 'Использовать редактор Verstka.io',
  biographies: 'Звёзды',
  socialDistribution: 'Дистрибуция',
  rss_zen_filter: 'Не выводить в Дзен',
  order_date: 'Дата создания',
  detail_image_source: 'Источник заходного фото',
  seasons: 'Сезонность',
  seo_title: 'SEO заголовок',
  seo_description: 'SEO описание',
  calories: 'Калорийность',
  proteins: 'Белки',
  fats: 'Жиры',
  carbohydrates: 'Углеводы',
  first_name: 'Имя',
  last_name: 'Фамилия',
  password: 'Пароль',
  gender: 'Пол',
  city: 'Город',
  user_field_value: {
    work_position: 'Должность',
    relation: 'Семейное положение',
    soc_url_facebook: 'Facebook',
    soc_url_instagram: 'Instagram',
    soc_url_vk: 'VK',
    soc_url_twitter: 'Twitter',
    soc_url_youtube: 'YouTube',
    soc_url_telegram: 'Telegram',
    yandex_email: 'Яндекс почта',
  },
  extra_fields: {
    disableComments: 'отключить комментарии',
    detailImageSource: 'Источник заходного фото',
    contentsEnabled: 'Включить содержание',
    contentsText: 'Текст перед содержанием',
    disableVideoRecommends: 'Выключить видеорекомендацию',
    noindex: 'Не индексировать поисковиками',
    negativeWordsCheckDisabled: 'Выключить проверку на негативные слова',
    factWidgetDisabled: 'Выключить виджет фактов',
    dzenNativeRssMode: 'Настройки для Дзен',
    advertisingAge: 'Рекламный материал → Возраст',
    isAdvertisingArticle: 'Рекламный материал → Рекламная статья',
    efficientTitleEnabled: 'Эффективность заголовка',
    openTypeExternalLinks: 'Открывать в новой вкладке',
    pushDescription: 'Push → Описание',
    pushName: 'Push → Название',
    healthWidget: 'Показывать плашку Здоровье',
    advertisingDomain: 'Рекламный материал → Рекламный домен',
    advertisingName: 'Рекламный материал → Название компании',
    advertisingGetField: 'Рекламный материал → GET-параметр',
    sendToMarketing: 'Отправить в маркетинг',
    seoInitiator: 'Заказчик текста',
    disableInitialCap: 'Отключить буквицу',
    trueLieWidget: 'Включить виджет Правда или ложь?',
    advertisingLegalInfo: 'Юридическая информация',
    advertisingOther: 'Рекламный материал → Прочее',
    singleSocialPostTemplate: 'Дистрибуция → Один шаблон для всех',
  },
  star: {
    occupations: 'Звезды → Род деятельности',
    birthday: 'Звезды → Дата рождения',
    death_date: 'Звезды → Дата смерти',
    gender: 'Звезды → Пол',
    weight: 'Звезды → Вес, кг',
    height: 'Звезды → Рост, см',
    education: 'Звезды → Образование',
    location: 'Звезды → Где живет сейчас',
    full_name: 'Звезды → Полное имя',
    meta_title: 'Звезды → Meta заголовок',
    meta_description: 'Звезды → Meta описание',
    meta_keywords: 'Звезды → Meta ключевые слова',
    married_with: 'Звезды → В браке с',
    is_inoagent: 'Звезды → Иноагент',
  },
  brand: {
    rus_name: 'Бренд → Название на русском',
    product: 'Бренд → Что производят',
    foundation_year: 'Бренд → Год основания',
    grid: 'Бренд → Размерная сетка',
    owner: 'Бренд → Владелец',
  },
}
