import { PollTypeCode } from '@/types/poll'

interface PollItem {
  id: number
  name: string
  code: PollTypeCode
}

const pollTypeItems: PollItem[] = [
  {
    id: 0,
    name: 'Обычный',
    code: PollTypeCode.SIMPLE,
  },
  {
    id: 1,
    name: 'Два варианта',
    code: PollTypeCode.TWO_OPTIONS,
  },
  {
    id: 4,
    name: 'Статьи',
    code: PollTypeCode.ARTICLES,
  },
]

const pollTypeMap = new Map<number | PollTypeCode, PollItem>()

pollTypeItems.forEach((item) => {
  pollTypeMap.set(item.id, item)
  pollTypeMap.set(item.code, item)
})

export { pollTypeItems, pollTypeMap }
