export const galleryConfig: CKEDITOR.config = {
  language: 'ru',
  toolbar: [['Link', 'Unlink']],
  height: '50px',
}

export const baseConfig: CKEDITOR.config = {
  language: 'ru',
  toolbar: [
    [
      'Undo',
      'Redo',
      '-',
      'Link',
      'Unlink',
      'Anchor',
      '-',
      'Maximize',
      '-',
      'Bold',
      'Italic',
      'Strike',
      'RemoveFormat',
      '-',
      'NumberedList',
      'BulletedList',
      '-',
      'Format',
    ],
  ],
}

export const articleConfig: CKEDITOR.config = {
  language: 'ru',
  extraPlugins: 'myImage, expert',
  removePlugins: 'resize, image',
  allowedContent: true,
  toolbar: [
    [
      'MyImage',
      'expert',
      '-',
      'Cut',
      'Copy',
      'Paste',
      'PasteText',
      'PasteFromWord',
      '-',
      'Undo',
      'Redo',
      '-',
      'Scayt',
      '-',
      'Link',
      'Unlink',
      'Anchor',
      '-',
      'Maximize',
      '-',
      'Source',
      '-',
      'Bold',
      'Italic',
      'Strike',
      'RemoveFormat',
      '-',
      'NumberedList',
      'BulletedList',
      '-',
      'Format',
    ],
  ],
}
