export const statsSwitchers = [
  {
    title: 'Сегодня',
    slug: 'today',
  },
  {
    title: 'Вчера',
    slug: 'yesterday',
  },
  {
    title: 'Неделя',
    slug: 'week',
  },
  {
    title: 'Месяц',
    slug: 'month',
  },
  {
    title: 'Квартал',
    slug: 'year-quarter',
  },
  {
    title: 'Год',
    slug: 'year',
  },
]
