import { Platform } from '@/types/platform'
import { Location } from 'vue-router'
import store from '@/store'

const isGlobalAdmin = store.getters['user/userIsGlobalAdmin']

export interface Link {
  title: string
  icon?: string
  img?: string
  disabled: boolean
  exact?: boolean
  to: Location
  children?: Link[]
  accessPlatforms?: Platform[]
}

export const mainLinks = (): Link[] => {
  return [
    {
      title: 'Главная',
      icon: '$home',
      disabled: false,
      to: {
        path: '/',
      },
    },
    {
      title: 'Модерация',
      icon: '$comment',
      disabled: false,
      to: {
        name: 'main-comments',
      },
      children: [
        {
          title: 'Модерация комментариев',
          disabled: false,
          to: {
            name: 'main-comments_moderation',
          },
        },
        {
          title: 'Модерация профилей',
          disabled: false,
          to: {
            name: 'profile-moderation',
          },
        },
        {
          title: 'Написать комментарий',
          disabled: false,
          to: {
            name: 'articles-for-comments',
          },
        },
      ],
    },
    {
      title: 'Пользователи',
      icon: 'mdi-account-group',
      disabled: !isGlobalAdmin,
      to: {
        name: 'user_list',
      },
    },
    {
      title: 'Роли пользователей',
      icon: 'mdi-account-group',
      disabled: false,
      to: {
        name: 'user_roles',
      },
    },
    {
      title: 'Справочники',
      icon: 'mdi-application',
      disabled: true,
      to: {
        path: '/catalog/',
      },
    },
    {
      title: 'Модули',
      icon: 'mdi-dice-5',
      disabled: true,
      to: {
        path: '/modules/',
      },
      children: [
        {
          title: 'Инструкции',
          disabled: true,
          to: {
            path: '/Instructions',
          },
        },
        {
          title: 'Что нового?',
          disabled: true,
          to: {
            path: '/What_new',
          },
        },
      ],
    },
    {
      title: 'Документация',
      icon: 'mdi-book-open',
      disabled: true,
      to: {
        path: '/docs/',
      },
    },
    {
      title: 'Календарь событий',
      icon: 'mdi-calendar',
      exact: false,
      disabled: false,
      to: {
        name: 'calendar-events',
      },
    },
    {
      title: 'Поддержка',
      icon: '$support',
      disabled: false,
      to: {
        name: 'support-main',
      },
    },
  ]
}

export const platformLinks = (platform: Platform): Link[] => {
  return [
    {
      title: 'Главная',
      icon: '$home',
      disabled: false,
      to: {
        name: 'home_platform',
        params: {
          platform,
        },
      },
    },
    {
      title: 'Контент',
      icon: '$content',
      disabled: false,
      to: {
        name: 'content',
        params: {
          platform,
        },
      },
      children: [
        {
          title: 'Статьи',
          disabled: false,
          to: {
            name: 'content_articles',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Видео',
          disabled: false,
          to: {
            name: 'content_widget_video',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Звезды',
          disabled: false,
          to: {
            name: 'content_stars',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Подкасты',
          disabled: false,
          to: {
            name: 'podcasts',
            params: {
              platform,
            },
          },
          children: [
            {
              title: 'Подкасты',
              disabled: false,
              to: {
                name: 'content_podcasts',
                params: {
                  platform,
                },
              },
            },
            {
              title: 'Эпизоды',
              disabled: false,
              to: {
                name: 'content_episodes',
                params: {
                  platform,
                },
              },
            },
          ],
        },
        {
          title: 'Бренды',
          disabled: false,
          to: {
            name: 'content_brands',
            params: {
              platform,
            },
          },
        },
        {
          title: 'План публикаций',
          disabled: false,
          to: {
            name: 'content_publication_plan',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Рецепты',
          disabled: false,
          to: {
            name: 'content_recipe_page',
            params: {
              platform,
            },
          },
          children: [
            {
              title: 'Рецепты',
              to: {
                name: 'content_recipe_list',
                params: {
                  platform,
                },
              },
              disabled: false,
            },
            {
              title: 'Продукты',
              to: {
                name: 'content_recipe_product_list',
                params: {
                  platform,
                },
              },
              disabled: false,
            },
            {
              title: 'Категории блюд',
              to: {
                name: 'content_recipe_dish_list',
                params: {
                  platform,
                },
              },
              disabled: false,
            },
          ],
          accessPlatforms: [
            Platform.goodhouse,
            Platform.cosmo,
            Platform.mhealth,
          ],
        },
        {
          title: 'Рубрики',
          disabled: false,
          to: {
            name: 'content_section',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Теги',
          disabled: false,
          to: {
            name: 'content_tag',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Авторы',
          disabled: false,
          to: {
            name: 'content_author',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Эксперты',
          disabled: false,
          to: {
            name: 'content_expert_list',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Виджеты',
          disabled: false,
          to: {
            name: 'content_widget',
            params: {
              platform,
            },
          },
          children: [
            {
              title: 'Галереи',
              disabled: false,
              to: {
                name: 'content_widget_gallery',
                params: {
                  platform,
                },
              },
            },
            {
              title: 'Опросы',
              disabled: false,
              to: {
                name: 'content_widget_poll',
                params: {
                  platform,
                },
              },
            },
            {
              title: 'Тесты',
              disabled: false,
              to: {
                name: 'content_widget_test',
                params: {
                  platform,
                },
              },
            },
            {
              title: 'Факты',
              disabled: false,
              to: {
                name: 'content_widget_fact',
                params: {
                  platform,
                },
              },
              accessPlatforms: [Platform.cosmo, Platform.goodhouse],
            },
            {
              title: 'Товарные подборки',
              disabled: true,
              to: {
                name: 'content_widget_selections',
                params: {
                  platform,
                },
              },
            },
            {
              title: 'Подборки рецептов',
              disabled: false,
              to: {
                name: 'content_widget_recipe_selection',
                params: {
                  platform,
                },
              },
              accessPlatforms: [
                Platform.goodhouse,
                Platform.cosmo,
                Platform.mhealth,
              ],
            },
          ],
        },
        {
          title: 'Спецформаты',
          disabled: true,
          to: {
            name: 'content_special',
            params: {
              platform,
            },
          },
          children: [
            {
              title: 'Гороскоп совместимости',
              disabled: true,
              to: {
                name: 'content_special_horoscopecompatibility',
                params: {
                  platform,
                },
              },
            },
            {
              title: 'Звёзды (Биографии)',
              disabled: true,
              to: {
                name: 'content_special_star',
                params: {
                  platform,
                },
              },
            },
            {
              title: 'Знаки зодиака',
              disabled: true,
              to: {
                name: 'content_special_handbook_zodiac',
                params: {
                  platform,
                },
              },
            },
            {
              title: 'Позы камасутры',
              disabled: true,
              to: {
                name: 'content_special_kamasutrapose',
                params: {
                  platform,
                },
              },
            },
            {
              title: 'Фотографии',
              disabled: true,
              to: {
                name: 'content_special_photos',
                params: {
                  platform,
                },
              },
            },
          ],
        },
        {
          title: 'Журналы',
          disabled: false,
          to: {
            name: 'content_issue',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Премия женщин',
          disabled: false,
          accessPlatforms: [Platform.goodhouse],
          to: {
            name: 'content_awards',
            params: {
              platform,
            },
          },
          children: [
            {
              title: 'Заявки',
              disabled: false,
              to: {
                name: 'content_awards_applications_list',
                params: {
                  platform,
                },
              },
            },
            {
              title: 'Истории',
              disabled: false,
              to: {
                name: 'content_awards_history_list',
                params: {
                  platform,
                },
              },
            },
            {
              title: 'Жюри',
              disabled: false,
              to: {
                name: 'content_awards_jury_list',
                params: {
                  platform,
                },
              },
            },
            {
              title: 'Закрепы для видео',
              disabled: false,
              to: {
                name: 'content_awards_fixed',
                params: {
                  platform,
                },
              },
            },
          ],
        },
      ],
    },
    {
      title: 'Обсуждение',
      icon: 'mdi-forum-outline',
      disabled: false,
      accessPlatforms: [Platform.cosmo],
      to: {
        name: 'discussion',
        params: {
          platform,
        },
      },
      children: [
        {
          title: 'Модерация топиков',
          disabled: false,
          to: {
            name: 'discussion_topic_moderation',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Список топиков',
          disabled: false,
          to: {
            name: 'discussion_topic',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Интересы',
          disabled: false,
          to: {
            name: 'discussion_interests',
            params: {
              platform,
            },
          },
        },
      ],
    },
    {
      title: 'Комментарии',
      icon: '$comment',
      disabled: false,
      to: {
        name: 'comments',
        params: {
          platform,
        },
      },
      children: [
        {
          title: 'Список комментариев',
          disabled: false,
          to: {
            name: 'comments_list',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Модерация',
          disabled: false,
          to: {
            name: 'comments_moderation',
            params: {
              platform,
            },
          },
        },
      ],
    },
    {
      title: 'Продвижение',
      icon: '$design',
      disabled: false,
      to: {
        name: 'design',
        params: {
          platform,
        },
      },
      children: [
        {
          title: 'Бренды',
          disabled: false,
          to: {
            name: 'design_brands_list',
            params: {
              platform,
            },
          },
          children: [
            {
              title: 'Закрепы',
              disabled: false,
              to: {
                name: 'design_homepage_fixed_brands',
                params: {
                  platform,
                },
              },
            },
            {
              title: 'Попблоки',
              disabled: false,
              to: {
                name: 'design_homepage_popblock_brands',
                params: {
                  platform,
                },
              },
            },
          ],
        },
        {
          title: 'Закрепы на главной',
          disabled: false,
          to: {
            name: 'design_homepage_fixed',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Закрепы в разделах',
          disabled: false,
          to: {
            name: 'design_homepage_fixed_section',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Закрепы для видео',
          disabled: false,
          to: {
            name: 'design_homepage_video-fixed',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Обвесы',
          disabled: false,
          to: {
            name: 'design_homepage_block',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Попблок',
          disabled: false,
          to: {
            name: 'design_homepage_popblock',
            params: {
              platform,
            },
          },
          accessPlatforms: [
            Platform.cosmo,
            Platform.grazia,
            Platform.goodhouse,
            Platform.esquire,
          ],
        },
        {
          title: 'Редакция рекомендует',
          disabled: false,
          to: {
            name: 'design_homepage_contest',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Статьи в скролле',
          disabled: false,
          to: {
            name: 'design_homepage_scroll_articles',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Видео в скролле',
          disabled: false,
          to: {
            name: 'design_homepage_scroll_videos',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Фичеры',
          disabled: false,
          to: {
            name: 'design_homepage_feature',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Плашки',
          disabled: false,
          to: {
            name: 'design_popup',
            params: {
              platform,
            },
          },
        },
      ],
    },
    {
      title: 'Аналитика',
      icon: '$analytics',
      disabled: false,
      to: {
        name: 'analytics',
        params: {
          platform,
        },
      },
      children: [
        {
          title: 'Сводка',
          disabled: false,
          to: {
            name: 'analytics_summary',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Статьи',
          disabled: false,
          to: {
            name: 'analytics_articles',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Поисковые системы',
          disabled: false,
          to: {
            name: 'analytics_search_engines',
            params: {
              platform,
            },
          },
        },
        // {
        //   title: 'Виды контента',
        //   disabled: false,
        //   to: {
        //     name: 'analytics_content-type',
        //     params: {
        //       platform,
        //     },
        //   },
        // },
        // {
        //   title: 'Основные показатели',
        //   disabled: false,
        //   to: {
        //     name: 'analytics_basic-index',
        //     params: {
        //       platform,
        //     },
        //   },
        // },
      ],
    },
    {
      title: 'Рекомендации',
      icon: '$recommendationIcon',
      disabled: false,
      to: {
        name: 'recommendation',
        params: {
          platform,
        },
      },
      children: [
        {
          title: 'Календарь',
          disabled: false,
          to: {
            name: 'recommendation-calendar',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Сводка новостей',
          disabled: false,
          to: {
            name: 'news-bulletin',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Архив',
          disabled: false,
          to: {
            name: 'archive',
            params: {
              platform,
            },
          },
        },
      ],
    },
    {
      title: 'Дистрибуция',
      icon: '$distribution',
      disabled: false,
      to: {
        name: 'distribution_list',
        params: {
          platform,
        },
      },
      children: [
        {
          title: 'Пуши на сегодня',
          disabled: false,
          to: {
            name: 'distribution_push_list',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Рассылки',
          disabled: false,
          to: {
            name: 'distribution_mailing_list',
            params: {
              platform,
            },
          },
          children: [
            {
              title: 'Интересы',
              disabled: false,
              to: {
                name: 'distribution_mailing_interest_list',
                params: {
                  platform,
                },
              },
            },
          ],
        },
        {
          title: 'План дистрибуции',
          disabled: false,
          to: {
            name: 'distribution_plan_list',
            params: {
              platform,
            },
          },
        },
      ],
    },
    {
      title: 'Маркетинг',
      icon: '$marketing',
      disabled: false,
      to: {
        name: 'marketing',
        params: {
          platform,
        },
      },
      children: [
        {
          title: 'Виджеты лидогенерации',
          disabled: true,
          to: {
            name: 'marketing_popupwidget',
            params: {
              platform,
            },
          },
        },
        {
          title: 'SEO',
          disabled: false,
          to: {
            name: 'marketing_seo',
            params: {
              platform,
            },
          },
        },
        {
          title: 'RSS',
          disabled: true,
          to: {
            name: 'marketing_rss',
            params: {
              platform,
            },
          },
        },
      ],
    },
    {
      title: 'Реклама и таргетинг',
      icon: '$ad_target',
      disabled: false,
      to: {
        name: 'adv',
        params: {
          platform,
        },
      },
      children: [
        {
          title: 'Переключение баннеров',
          disabled: false,
          to: {
            name: 'adv_switch',
            params: {
              platform,
            },
          },
          accessPlatforms: [
            Platform.cosmo,
            Platform.goodhouse,
            Platform.popmech,
            Platform.grazia,
            Platform.mhealth,
          ],
        },
        {
          title: 'Реклама по тегу',
          disabled: false,
          to: {
            name: 'adv_tag',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Рекламные материалы',
          disabled: false,
          to: {
            name: 'adv_ord',
          },
        },
      ],
    },
    {
      title: 'Настройки',
      icon: '$settings',
      disabled: false,
      to: {
        name: 'settings',
        params: {
          platform,
        },
      },
      children: [
        {
          title: 'Контакты',
          disabled: false,
          to: {
            name: 'settings_contacts',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Пользователи',
          disabled: !isGlobalAdmin,
          to: {
            name: 'settings_users',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Технические страницы',
          disabled: false,
          to: {
            name: 'settings_static',
            params: {
              platform,
            },
          },
        },
        {
          title: 'Индивидуальные настройки',
          disabled: false,
          to: {
            name: 'settings_user_private',
            params: {
              platform,
            },
          },
        },
      ],
    },
    {
      title: 'Поддержка',
      icon: '$support',
      disabled: false,
      to: {
        name: 'support',
        params: {
          platform,
        },
      },
    },
    {
      title: 'База статей',
      icon: '$articles',
      disabled: true,
      to: {
        name: 'imlib',
        params: {
          platform,
        },
      },
    },
  ]
}
