import { Platform } from '@/types/platform'

import { Config } from './types'

import * as bazaarConfig from './bazaar'
import * as cosmoConfig from './cosmo'
import * as esquireConfig from './esquire'
import * as goodhouseConfig from './goodhouse'
import * as graziaConfig from './grazia'
import * as mhealthConfig from './mhealth'
import * as popmechConfig from './popmech'
import * as robbConfig from './robb'

export default function getVideoConfig(platform: Platform): Config {
  switch (platform) {
    case Platform.bazaar:
      return bazaarConfig

    case Platform.esquire:
      return esquireConfig

    case Platform.goodhouse:
      return goodhouseConfig

    case Platform.grazia:
      return graziaConfig

    case Platform.mhealth:
      return mhealthConfig

    case Platform.popmech:
      return popmechConfig

    case Platform.robb:
      return robbConfig

    default:
      return cosmoConfig
  }
}
