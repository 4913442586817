import {
  CountersConfig,
  Platform,
  PlatformParams,
  PlatformTitle,
  ProjectID,
  PlatformOrigin,
  PlatformShortTitle,
} from '@/types/platform'

export const platformsSettings: PlatformParams[] = [
  {
    title: 'Главная панель',
    fullTitleColor: '#ffffff',
    shortTitle: PlatformShortTitle.im,
    slug: Platform.im,
    background: '#ffffff',
    backgroundNav: '#ffffff',
    borderColor: '#b510ac',
    color: '#303030',
    projectId: ProjectID.im,
    origin: PlatformOrigin.im,
    counter: CountersConfig.im,
    fullTitle: '',
    to: {
      name: 'home',
    },
  },
  {
    title: PlatformTitle.cosmo,
    fullTitleColor: '#ffffff',
    shortTitle: PlatformShortTitle.cosmo,
    slug: Platform.cosmo,
    fullTitle: 'thevoicemag.ru',
    origin: PlatformOrigin.cosmo,
    background: '#ed2891',
    backgroundNav: '#ffffff',
    borderColor: '#ed2891',
    color: '#ffffff',
    projectId: ProjectID.cosmo,
    counter: CountersConfig.cosmo,
    to: {
      name: 'home_platform',
      params: {
        platform: Platform.cosmo,
      },
    },
  },
  {
    title: PlatformTitle.bazaar,
    fullTitleColor: '#ffffff',
    shortTitle: PlatformShortTitle.bazaar,
    slug: Platform.bazaar,
    fullTitle: 'thesymbol.ru',
    origin: PlatformOrigin.bazaar,
    background: '#000000',
    backgroundNav: '#ffffff',
    borderColor: '#000000',
    color: '#ffffff',
    projectId: ProjectID.bazaar,
    counter: CountersConfig.bazaar,
    to: {
      name: 'home_platform',
      params: {
        platform: Platform.bazaar,
      },
    },
  },
  {
    title: PlatformTitle.grazia,
    fullTitleColor: '#ffffff',
    shortTitle: PlatformShortTitle.grazia,
    slug: Platform.grazia,
    fullTitle: 'Graziamagazine.ru',
    origin: PlatformOrigin.grazia,
    background: '#000000',
    backgroundNav: '#ffffff',
    borderColor: '#000000',
    color: '#303030',
    projectId: ProjectID.grazia,
    counter: CountersConfig.grazia,
    to: {
      name: 'home_platform',
      params: {
        platform: Platform.grazia,
      },
    },
  },
  {
    title: PlatformTitle.mhealth,
    fullTitleColor: '#ffffff',
    shortTitle: PlatformShortTitle.mhealth,
    slug: Platform.mhealth,
    fullTitle: 'mentoday.ru',
    origin: PlatformOrigin.mhealth,
    background: '#bd160b',
    backgroundNav: '#ffffff',
    borderColor: '#bd160b',
    color: '#ffffff',
    projectId: ProjectID.mhealth,
    counter: CountersConfig.mhealth,
    to: {
      name: 'home_platform',
      params: {
        platform: Platform.mhealth,
      },
    },
  },
  {
    title: PlatformTitle.esquire,
    fullTitleColor: '#ffffff',
    shortTitle: PlatformShortTitle.esquire,
    slug: Platform.esquire,
    fullTitle: 'pravilamag.ru',
    origin: PlatformOrigin.esquire,
    background: '#1d1d1b',
    backgroundNav: '#ffffff',
    borderColor: '#1d1d1b',
    color: '#ffffff',
    projectId: ProjectID.esquire,
    counter: CountersConfig.esquire,
    to: {
      name: 'home_platform',
      params: {
        platform: Platform.esquire,
      },
    },
  },
  {
    title: PlatformTitle.popmech,
    fullTitleColor: '#ffffff',
    shortTitle: PlatformShortTitle.popmech,
    slug: Platform.popmech,
    fullTitle: 'techinsider.ru',
    origin: PlatformOrigin.popmech,
    background: '#000000',
    backgroundNav: '#ffffff',
    borderColor: '#000000',
    color: '#ffffff',
    projectId: ProjectID.popmech,
    counter: CountersConfig.popmech,
    to: {
      name: 'home_platform',
      params: {
        platform: Platform.popmech,
      },
    },
  },
  {
    title: PlatformTitle.goodhouse,
    fullTitleColor: '#ffffff',
    shortTitle: PlatformShortTitle.goodhouse,
    slug: Platform.goodhouse,
    fullTitle: 'novochag.ru',
    origin: PlatformOrigin.goodhouse,
    background: '#d61122',
    backgroundNav: '#ffffff',
    borderColor: '#d61122',
    color: '#ffffff',
    projectId: ProjectID.goodhouse,
    counter: CountersConfig.goodhouse,
    to: {
      name: 'home_platform',
      params: {
        platform: Platform.goodhouse,
      },
    },
  },
  {
    title: PlatformTitle.robb,
    fullTitleColor: '#ffffff',
    shortTitle: PlatformShortTitle.robb,
    slug: Platform.robb,
    fullTitle: 'Robb.Report',
    origin: PlatformOrigin.robb,
    background: '#b49d69',
    backgroundNav: '#ffffff',
    borderColor: '#b49d69',
    color: '#ffffff',
    projectId: ProjectID.robb,
    counter: CountersConfig.robb,
    to: {
      name: 'home_platform',
      params: {
        platform: Platform.robb,
      },
    },
  },
  {
    title: PlatformTitle.girlspower,
    fullTitleColor: '#000000',
    shortTitle: PlatformShortTitle.girlspower,
    slug: Platform.girlspower,
    fullTitle: 'idolmedia.ru',
    origin: PlatformOrigin.girlspower,
    background: '#BBF500',
    backgroundNav: '#ffffff',
    borderColor: '#BBF500',
    color: '#000000',
    projectId: ProjectID.girlspower,
    counter: CountersConfig.girlspower,
    to: {
      name: 'home_platform',
      params: {
        platform: Platform.girlspower,
      },
    },
  },
]
