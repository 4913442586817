import { Platform } from '@/types/platform'
import {
  UserPermission,
  UserPermissionField,
  UserPermissionPage,
  UserPermissionWidgetField,
} from '@/types/user'

export const articleWidgets = (): {
  title: string
  slug: string
  icon: string
  tag?: string
  text?: string
  svg: boolean
  commandToExec?: string
  quantity?: number
  accessPlatforms?: Platform[]
  permissions?: {
    page: UserPermissionPage
    list: UserPermission[]
    fields?: {
      field: UserPermissionField
      permissions: UserPermission[]
    }[]
  }[]
}[] => {
  const widgets = [
    {
      title: 'Абзац',
      slug: 'text',
      icon: 'mdi-format-size',
      tag: 'p',
      text: '',
      svg: false,
    },
    {
      title: 'Ненумерованный список',
      slug: 'text',
      icon: 'format_list_bulleted',
      commandToExec: 'bulletedlist',
      tag: 'ul',
      text: '<ul><li></li></ul>',
      svg: false,
    },
    {
      title: 'Нумерованный список',
      slug: 'text',
      icon: 'format_list_numbered',
      commandToExec: 'numberedlist',
      tag: 'ol',
      text: '<ol><li></li></ol>',
      svg: false,
    },
    {
      title: 'HTML-код',
      slug: 'html',
      icon: 'mdi-code-tags',
      svg: false,
      permissions: [
        {
          page: UserPermissionPage.WIDGET,
          list: [UserPermission.EDIT],
          fields: [
            {
              field: UserPermissionWidgetField.HTML,
              permissions: [UserPermission.EDIT],
            },
          ],
        },
      ],
    },
    {
      title: 'Фото',
      slug: 'imageset',
      icon: 'mdi-image-outline',
      svg: false,
    },
    {
      title: 'Цитата',
      slug: 'cite',
      icon: 'mdi-format-quote-close-outline',
      svg: false,
    },
    {
      title: 'Факт',
      slug: 'fact',
      icon: 'mdi-playlist-check',
      svg: false,
    },
    {
      title: 'Эксперт',
      slug: 'newExpert',
      icon: require('@/assets/icons/expert.svg'),
      svg: true,
    },
    {
      title: 'Опрос',
      slug: 'poll',
      icon: 'mdi-chart-box-outline',
      svg: false,
    },
    {
      title: 'Тест',
      slug: 'test',
      icon: 'mdi-checkbox-outline',
      svg: false,
    },
    {
      title: 'Галерея',
      slug: 'gallery',
      icon: 'mdi-view-carousel-outline',
      svg: false,
    },
    {
      title: 'Видео',
      slug: 'video',
      icon: require('@/assets/icons/play.svg'),
      svg: true,
    },
    // {
    //   title: 'Видео код',
    //   slug: 'video',
    //   icon: 'mdi-television-play',
    //   svg: false,

    //   raw: {
    //     added_via: 'code',
    //   },
    // },
    // {
    //   title: 'Код',
    //   slug: 'embed-element',
    //   icon: 'mdi-code-tags',
    //   svg: false,
    // },
    {
      title: 'TikTok',
      slug: 'tiktok',
      icon: require('@/assets/icons/tiktok.svg'),
      svg: true,
    },
    {
      title: 'Сoub',
      slug: 'coub',
      icon: require('@/assets/icons/coub.svg'),
      svg: true,
    },
    {
      title: 'Vkontakte',
      slug: 'vk',
      icon: require('@/assets/icons/vk-c.svg'),
      svg: true,
    },
    {
      title: 'Telegram',
      slug: 'telegram',
      icon: require('@/assets/icons/telegram.svg'),
      svg: true,
    },
    {
      title: 'Одноклассники',
      slug: 'ok',
      icon: require('@/assets/icons/ok-c.svg'),
      svg: true,
    },
    {
      title: 'Giphy',
      slug: 'giphy',
      icon: require('@/assets/icons/gif.svg'),
      svg: true,
    },
    {
      title: 'Подборка рецептов',
      slug: 'recipe_compilation',
      icon: require('@/assets/icons/recipe_compilation.svg'),
      svg: true,
      accessPlatforms: [Platform.goodhouse, Platform.cosmo, Platform.mhealth],
    },
    {
      title: 'Это интересно',
      slug: 'interest',
      icon: 'mdi-lightbulb-on-outline',
      svg: false,
    },
    {
      title: 'Аудио',
      slug: 'audio',
      icon: require('@/assets/icons/audio.svg'),
      svg: true,
    },
    {
      title: 'Кнопка автоперехода',
      slug: 'redirectButton',
      icon: '$moveDown',
      svg: false,
      permissions: [
        {
          page: UserPermissionPage.WIDGET,
          list: [UserPermission.EDIT],
          fields: [
            {
              field: UserPermissionWidgetField.REDIRECT_BUTTON,
              permissions: [UserPermission.EDIT],
            },
          ],
        },
      ],
    },
  ]

  return widgets
}

export const articleSocials = (
  platform: Platform
): {
  title: string
  icon: string
  slug: string
  accessPlatforms?: Platform[]
}[] => {
  const socials = [
    {
      title: 'Facebook',
      icon: require('@/assets/icons/fb.svg'),
      slug: 'fb',
    },
    {
      title: 'VK',
      icon: require('@/assets/icons/vk.svg'),
      slug: 'vk',
    },
    {
      title: 'OK',
      icon: require('@/assets/icons/ok.svg'),
      slug: 'ok',
    },
    {
      title: 'Twitter',
      icon: require('@/assets/icons/twitter.svg'),
      slug: 'twitter',
    },
    {
      title: 'Telegram',
      icon: require('@/assets/icons/telegram-logo.svg'),
      slug: 'tg',
    },
    {
      title: 'Viber',
      icon: require('@/assets/icons/viber.svg'),
      slug: 'viber',
      accessPlatforms: [Platform.goodhouse, Platform.cosmo],
    },
  ]

  return socials.filter(
    (social) =>
      !social.accessPlatforms || social.accessPlatforms.includes(platform)
  )
}
