import { ArticleTypeCode } from '@/types/article'
import { Config } from './types'

export const enabledStars = true
export const enabledArticleIssue = false
export const canDisableInitialCap = true
export const enabledTrueLieWidget = false
export const canSendToKadam = false
export const enabledRelatedArticles = false
export const enabledInviteDiscussion = false

export const defaultSectionId: Config['defaultSectionId'] = ({ type }) => {
  return type === ArticleTypeCode.NEWS ? 753 : null
}

export const canSendToMarketing: Config['canSendToMarketing'] = () => {
  return false
}

export const articleTitleMaxLength: Config['articleTitleMaxLength'] = ({
  type,
}) => {
  if (type === ArticleTypeCode.VIDEO) {
    return 100
  }

  return 150
}

export const enabledExtraArtcleInfo: Config['enabledExtraArtcleInfo'] = () => {
  return false
}
