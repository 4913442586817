import { ImageSource } from '@/types/image'
import { Platform } from '@/types/platform'

export function imageSources({
  platform,
}: {
  platform: Platform
}): ImageSource[] {
  const items: ImageSource[] = [
    {
      text: 'Legion-Media',
      value: 'Legion-Media',
    },
    {
      text: 'ЛИПАТНИКОВ ИП // Personastars.com',
      value: 'ЛИПАТНИКОВ ИП // Personastars.com',
    },
    {
      text: 'GettyImages',
      value: 'GettyImages',
    },
    {
      text: 'Gallo images',
      value: 'Gallo images',
    },
    {
      text: 'Splash',
      value: 'Splash',
    },
    {
      text: 'Агентство «Москва»',
      value: 'Агентство «Москва»',
    },
    {
      text: 'Unsplash',
      value: 'Unsplash',
    },
    {
      text: 'Shutterstock.com',
      value: 'Shutterstock.com',
    },
    {
      text: 'East News',
      value: 'East News',
    },
    {
      text: 'ТАСС',
      value: 'ТАСС',
    },
    {
      text: 'РИА Новости',
      value: 'РИА Новости',
    },
    {
      text: 'Коммерсант',
      value: 'Коммерсант',
    },
    {
      text: 'ЛИПАТНИКОВ ИП // Personastars.com',
      value: 'ЛИПАТНИКОВ ИП // Personastars.com',
    },
    {
      text: 'IMAX TREE SRL',
      value: 'IMAX TREE SRL',
    },
    {
      text: 'Фотодом',
      value: 'Фотодом',
    },
    {
      text: 'Reuters',
      value: 'Reuters',
    },
    {
      text: 'Соцсети',
      value: 'Соцсети',
    },
    {
      text: 'Кадр из видео',
      value: 'Кадр из видео',
    },
    {
      text: 'Архивы пресс-службы',
      value: 'Архивы пресс-службы',
    },
    {
      text: 'ВИЛТА',
      value: 'ВИЛТА',
    },
    {
      text: 'Freepik',
      value: 'Freepik',
    },
    {
      text: 'Кинопоиск',
      value: 'Кинопоиск',
    },
    {
      text: 'МИА «Россия Сегодня»',
      value: 'МИА «Россия Сегодня»',
    },
    {
      text: 'Created by techinsider.ru using the Midjourney web app',
      value: 'Created by techinsider.ru using the Midjourney web app',
      access: [Platform.popmech],
    },
  ]

  return items.reduce<ImageSource[]>((acc, item) => {
    if (!item.access?.length || item.access.includes(platform)) {
      acc.push(Object.freeze(item))
    }

    return acc
  }, [])
}
