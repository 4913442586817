import { Role } from '@/types/role'

export const userRoles = new Map<Role, string>([
  [Role.SIRIUS_USER, 'Пользователь Сириуса'],
  [Role.ADMIN, 'Админ'],
  [Role.CHIEF_EDITOR, 'Шеф-редактор'],
  [Role.EDITOR, 'Редактор'],
  [Role.ADV_EDITOR, 'Редактор рекламы'],
  [Role.RECIPE_EDITOR, 'Редактор рецептов'],
  [Role.MAILING_MANAGER, 'Менеджер рассылок'],
  [Role.YANDEX_METRICS_USER, 'Пользователь метрики'],
  [Role.VERSTKA_MANAGER, 'Менеджер Verstka'],
  [Role.BRAND_MANAGER, 'Менеджер брендов'],
  [Role.COMMENT_MANAGER, 'Менеджер комментариев'],
  [Role.COMMUNITY_MANAGER, 'Менеджер тем'],
])
